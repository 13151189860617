import { AxiosRequestConfig } from 'axios';

import { authApi, publicApi } from '@/config/api';
import { GetDetailModeleApiResponse, Photo } from '@/types/apis/model';

const SERVICE_PATH = '/models';

export const getDetailModel = async ({
  id,
  token,
  params,
}: {
  id: string;
  token?: string;
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const api = token ? authApi(token) : publicApi;

    const { data } = await api<GetDetailModeleApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${id}`,
      params,
    });

    const modelsDetail = data.album.photos.map((item) => ({
      id: item.id,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      image: item.image,
      user: item.user,
      totalViews: item.totalViews,
      totalLikes: item.totalLikes,
      isLiked: item.isLiked,
    })) as Photo[];

    // console.log(data.album.photos, token, 'check models detail456');

    const model = data.model;

    return {
      modelsDetail,
      model,
      meta: data.album.pagination,
      totalLikes: data.album.totalLikes,
      albumId: data.album.id,
      totalViews: data.album.totalViews,
      isLiked: data.album.isLiked,
    };
  } catch (error) {
    return null;
  }
};
