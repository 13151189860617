import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { toast } from 'react-toastify';

import { like } from '@/services/apis/user';

interface LikeMutationProps {
  id: number;
  type: 'article' | 'photo' | 'album';
}

const useUpdateLikeMutation = () => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const likeMutation = useMutation({
    mutationFn: async ({ id, type }: LikeMutationProps) => {
      if (!session?.token) {
        throw new Error('User is not logged in');
      }

      return await like(id, type, session.token);
    },
    onSuccess: async (_, { id, type }) => {
      // Invalidate the specific like query
      await queryClient.invalidateQueries({
        queryKey: ['like', id],
      });

      // Invalidate the article/photo/model query to update like count
      await queryClient.invalidateQueries({
        queryKey: [type, id],
      });

      toast.success('Updated like successfully');
    },
    onError: (error) => {
      console.error('Like mutation error:', error);
      toast.error('Please login to like');
    },
  });

  return {
    likeMutation,
    isLiking: likeMutation.isPending,
  };
};

export default useUpdateLikeMutation;
