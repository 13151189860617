import * as React from 'react';
import { cn } from '@nextui-org/theme';
import Link from 'next/link';
import { format } from 'date-fns';
import { CalendarBlank } from '@phosphor-icons/react/dist/ssr';
import { Avatar } from '@nextui-org/avatar';
import { Divider } from '@nextui-org/divider';

import { FallbackImage, ThumbnailTags } from '@/components';
import { Article } from '@/models/article';
import { caption, heading } from '@/theme/typography';

interface FullWidthArticleThumbnailProps {
  article: Article;
  href: string;
  className?: string;
  createdByVisible?: boolean;
}

const FullWidthArticleThumbnail: React.FC<FullWidthArticleThumbnailProps> = ({
  article,
  href,
  className,
  createdByVisible = true,
}) => {
  return (
    <Link className={cn('w-ful relative aspect-video max-h-[480px]', className)} href={href}>
      <div className='absolute inset-0 bg-gradient-to-t from-neutral-black opacity-60' />

      <hgroup className='absolute bottom-0 left-0 flex w-full flex-col gap-2 p-6 text-neutral-white'>
        <ThumbnailTags tags={article.categories?.map(({ id, name }) => ({ id, label: name }))} />

        <h3 className={heading({ size: 'h4' })}>{article.title}</h3>

        {createdByVisible && (
          <div className={cn(caption(), 'flex flex-row items-center gap-3')}>
            {article.author && (
              <>
                <div className={cn('flex flex-row items-center gap-2')}>
                  <Avatar
                    className='h-7 w-7 border-2 border-gray-100'
                    src={article.author?.avatar?.url}
                  />
                  <span>{article.author?.name}</span>
                </div>

                <Divider className={cn('h-3 bg-neutral-white')} orientation='vertical' />
              </>
            )}

            <div className={cn(caption(), 'flex flex-row items-center gap-2')}>
              <CalendarBlank className='w-5' />
              {format(article.createdAt, 'd MMM yyyy').toUpperCase()}
            </div>
          </div>
        )}
      </hgroup>

      <FallbackImage
        alt={article.title}
        className='h-full w-full object-cover'
        height={560}
        src={article.cover?.formats?.medium?.url ?? ''}
        width={1000}
      />
    </Link>
  );
};

export default FullWidthArticleThumbnail;
