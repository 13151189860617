import { Avatar } from '@nextui-org/avatar';
import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import Link from 'next/link';
import React from 'react';
import { toast } from 'react-toastify';

import { useUserFollowMutation } from '@/hooks/mutations';
import { body, caption } from '@/theme/typography';
import { Image } from '@/types/apis/image';

interface FollowingUserItemProps {
  id: number;
  name: string;
  username: string;
  avatar?: Image;
}

export const FollowingUserItem: React.FC<FollowingUserItemProps> = ({
  id,
  name,
  username,
  avatar,
}) => {
  const [isFollowing, setIsFollowing] = React.useState(true);

  const { followMutation, unfollowMutation } = useUserFollowMutation();

  const handleToggleFollow = async ({ username }: { username: string }) => {
    setIsFollowing((prev) => !prev);

    try {
      await (isFollowing ? unfollowMutation : followMutation).mutateAsync(username);
      toast.success(`${isFollowing ? 'Unfollow' : 'Follow'} success.`);
    } catch (error) {
      setIsFollowing((prev) => !prev);
      toast.error(`${isFollowing ? 'Unfollow' : 'Follow'} failed. Please try again later.`);
    }
  };

  return (
    <div key={id} className='flex flex-row items-center justify-between gap-6'>
      <Link className='flex flex-row gap-5' href={`/${username}`}>
        <Avatar className='h-12 w-12 border-2 border-gray-100' src={avatar?.url} />
        <div className='flex flex-col'>
          <div className={cn(body({ weight: 'medium' }))}>{name}</div>
          <div className={cn(caption(), 'text-gray-500')}>@{username}</div>
        </div>
      </Link>

      <Button
        className='border-gray-50'
        isLoading={isFollowing ? unfollowMutation.isPending : followMutation.isPending}
        radius='none'
        variant='bordered'
        onClick={() => handleToggleFollow({ username })}
      >
        {isFollowing ? 'Following' : 'Follow'}
      </Button>
    </div>
  );
};
