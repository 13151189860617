import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import {
  CreateAlbumApiRequest,
  GetAlbumByIdApiResponse,
  GetMyAlbumsApiResponse,
  UpdateAlbumApiRequest,
} from '@/types/apis/album';
import { Album } from '@/models/album';

const SERVICE_PATH = '/albums';

export const getMyAlbums = async (
  token: string,
  { params }: { params?: AxiosRequestConfig['params'] },
) => {
  try {
    const { data: res } = await publicApi<GetMyAlbumsApiResponse>({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: SERVICE_PATH,
      params,
    });

    return res.results.map(
      (item) =>
        ({
          ...item,

          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        }) as Album,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getMyAlbums` API Error', error);

    return null;
  }
};

export const getAlbumById = async (
  id: number,
  token: string,
  { params }: { params?: AxiosRequestConfig['params'] },
) => {
  try {
    const { data: res } = await publicApi<GetAlbumByIdApiResponse>({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
      params,
    });

    return {
      ...res,

      createdAt: new Date(res.createdAt),
      updatedAt: new Date(res.updatedAt),
    } as Album;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getAlbumById` API Error', error);

    return null;
  }
};

export const createALbum = async ({
  data,
  token,
}: {
  data: CreateAlbumApiRequest;
  token: string;
}) => {
  try {
    await publicApi({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: SERVICE_PATH,
      data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`createALbum` API Error', error);
  }
};

export const updateAlbum = async ({
  id,
  data,
  token,
}: {
  id: number;
  data: UpdateAlbumApiRequest;
  token: string;
}) => {
  try {
    await publicApi({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
      data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`updateAlbum` API Error', error);
  }
};

export const deleteAlbum = async (id: number, token: string) => {
  try {
    await publicApi({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`deleteAlbum` API Error', error);
  }
};

export const increaseAlbumShares = async (albumId: number) => {
  try {
    await publicApi({
      method: 'POST',
      url: `/gallery/${albumId}/increase-share`,
    });
  } catch (error) {
    console.error('`increaseAlbumShares` API Error', error);
  }
};
