'use client';

import * as React from 'react';
import {
  Tab,
  Tabs as NextUITabs,
  TabsProps as NextUITabsProps,
  TabItemProps,
} from '@nextui-org/tabs';
import { cn } from '@nextui-org/theme';

import { heading } from '@/theme/typography';
import { TabProps } from '@/types/editor/tab';

interface TabsBarProps extends NextUITabsProps {
  items: TabItemProps[] | TabProps[];
}

const Tabs: React.FC<TabsBarProps> = ({ items, classNames, className, ...others }) => {
  return (
    <NextUITabs
      aria-label='Tabs'
      className={cn('overflow-x-auto', className)}
      classNames={{
        ...classNames,
        tab: cn('h-auto', classNames?.tab),
        tabContent: cn(
          heading({ size: 'h5', weight: 'medium' }),
          'group-data-[selected=true]:font-bold group-data-[selected=true]:text-primary p-4',
          classNames?.tabContent,
        ),
        cursor: cn('bg-primary shadow-none', classNames?.cursor),
      }}
      variant='underlined'
      {...others}
    >
      {items.map((item) => {
        const { key, title, id, ...others } = item as TabItemProps;

        return <Tab key={key || id} title={title} {...others} />;
      })}
    </NextUITabs>
  );
};

export default Tabs;
