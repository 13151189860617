import * as React from 'react';
import { Avatar } from '@nextui-org/avatar';
import Link from 'next/link';

import { User } from '@/models/user';

interface ArticleThumbnailAuthorProps {
  author: User;
}

const ArticleThumbnailAuthor: React.FC<ArticleThumbnailAuthorProps> = ({ author }) => {
  return (
    <Link
      className='flex cursor-pointer flex-row items-center gap-2 hover:underline'
      href={`/${author.username}`}
    >
      <Avatar className='h-7 w-7 border-2 border-gray-100' src={author.avatar?.url} />
      <span>{author.name}</span>
    </Link>
  );
};

export default ArticleThumbnailAuthor;
