import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import {
  createCommentInArticle,
  deleteCommentInArticle,
  updateCommentInArticle,
} from '@/services/apis/comment';

const useArticleCommentMutation = (articleId: number, isAlbum?: boolean) => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const createMutation = useMutation({
    mutationFn: async (content: string) => {
      if (!session?.user) throw new Error('User is not logged in');

      await createCommentInArticle({
        articleId,
        isAlbum,
        data: { content },
        token: session?.token ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['article-comments', articleId],
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ commentId, content }: { commentId: number; content: string }) => {
      if (!session?.user) throw new Error('User is not logged in');

      await updateCommentInArticle({
        articleId,
        isAlbum,
        commentId,
        data: { content },
        token: session?.token ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['article-comments', articleId],
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (commentId: number) => {
      if (!session?.user) throw new Error('User is not logged in');

      await deleteCommentInArticle({
        commentId,
        articleId,
        isAlbum,
        authorId: Number(session.user.id),
        token: session.token ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['article-comments', articleId],
      });
    },
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
  };
};

export default useArticleCommentMutation;
