'use client';

import { Avatar } from '@nextui-org/avatar';
import { Button } from '@nextui-org/button';
import { Chip } from '@nextui-org/chip';
import { cn } from '@nextui-org/theme';
import { DotsThree } from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { formatDistance } from 'date-fns';
import { Textarea } from '@nextui-org/input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';

import { body, heading } from '@/theme/typography';
import { CommentThread } from '@/models/comment';

interface CommentItemProps {
  comment: CommentThread;
  reactionCount?: number;
  isAuthor?: boolean;
  isMe?: boolean;
  loading?: boolean;
  className?: string;

  onReact: () => void;
  onEdit: (id: number, content: string) => void;
  onDelete: (id: number) => Promise<void>;
}

interface FormAttributes {
  [key: `content-${number}`]: string;
}

const CommentItem: React.FC<CommentItemProps> = ({
  comment: { id, content, author, createdAt },
  reactionCount = 0,
  onReact,
  onEdit,
  onDelete,
  className,
  loading,
  isMe,
  isAuthor,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const { register, handleSubmit } = useForm<FormAttributes>({
    defaultValues: { [`content-${id}`]: content },
  });

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleFinishEdit: SubmitHandler<FormAttributes> = (formData) => {
    onEdit(id, formData[`content-${id}`]);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const commentActions = React.useMemo(
    () =>
      isMe
        ? [
            {
              key: 'edit',
              label: 'Edit',
              onClick: handleStartEdit,
            },
            {
              key: 'delete',
              label: 'Delete',
              onClick: () => onDelete(id),
              color: 'danger',
            },
          ]
        : [
            {
              key: 'report',
              label: 'Report this response',
              onClick: () => {},
            },
          ],
    [isMe, id, onDelete],
  );

  return (
    <motion.div
      className={cn('flex w-full flex-col gap-4', className)}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      transition={{ delay: 0.2, type: 'tween' }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
    >
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row gap-4'>
          <Avatar className='h-12 w-12 border-2 border-gray-100' src={author.avatar?.url} />

          <div className='flex flex-col'>
            <div className='flex flex-row items-center gap-2'>
              <h3 className={heading({ size: 'h6', weight: 'medium' })}>{author.name}</h3>

              {isMe && (
                <Chip className='bg-blue-50' color='primary' radius='none' size='sm' variant='flat'>
                  You
                </Chip>
              )}

              {!isMe && isAuthor && (
                <Chip color='primary' radius='none' size='sm' variant='flat'>
                  Author
                </Chip>
              )}
            </div>
            <span className='text-gray-600'>{formatDistance(createdAt, new Date())}</span>
          </div>
        </div>

        <Dropdown
          showArrow
          classNames={{
            base: 'before:bg-neutral-white',
            content: 'p-0 border-small border-divider bg-neutral-white rounded-none',
          }}
          placement='bottom-end'
          radius='sm'
          size='lg'
        >
          <DropdownTrigger>
            <Button isIconOnly variant='light'>
              <DotsThree size={24} weight='bold' />
            </Button>
          </DropdownTrigger>

          <DropdownMenu aria-label='Comment action' className='p-3' variant='light'>
            {commentActions.map(({ label, color, key, ...others }, index) => (
              <DropdownItem
                key={key}
                color={color as 'danger'}
                showDivider={index !== commentActions.length - 1}
                {...others}
              >
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      {isEditing ? (
        <div className='flex w-full flex-col items-start gap-4 border-2 p-4'>
          <Textarea
            className={cn('w-full')}
            classNames={{
              inputWrapper: cn(
                'shadow-none bg-neutral-white p-0 w-full min-h-min',
                'data-[hover=true]:!bg-neutral-white group-data-[hover=true]:!bg-neutral-white',
                'data-[focus=true]:!bg-neutral-white group-data-[focus=true]:!bg-neutral-white',
              ),
              innerWrapper: 'gap-2',
              input: cn(body(), 'text-neutral-black self-center'),
            }}
            disabled={loading}
            minRows={1}
            placeholder='What are your thoughts?'
            radius='none'
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) handleSubmit(handleFinishEdit)();
            }}
            {...register(`content-${id}`)}
          />

          <div className='flex flex-row gap-3 self-end'>
            <Button disabled={loading} radius='none' variant='light' onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={loading}
              isLoading={loading}
              radius='none'
              variant='solid'
              onClick={handleSubmit(handleFinishEdit)}
            >
              Edit
            </Button>
          </div>
        </div>
      ) : (
        <p className={body()}>{content}</p>
      )}

      <div className='-ml-2 flex flex-row items-center gap-2'>
        {/* <ReactButton count={reactionCount} onClick={onReact} /> */}
      </div>
    </motion.div>
  );
};

export default CommentItem;
