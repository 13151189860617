'use client';

import { Avatar } from '@nextui-org/avatar';
import { Button } from '@nextui-org/button';
import { Textarea, TextAreaProps } from '@nextui-org/input';
import { cn } from '@nextui-org/theme';
import * as React from 'react';

import { body } from '@/theme/typography';

interface CommentInputProps extends TextAreaProps {
  avatarUrl: string;
  onSend?: (content: string) => void;
  disabled?: boolean;
  loading?: boolean;
}

const CommentInput = React.forwardRef<HTMLTextAreaElement, CommentInputProps>(
  ({ className, avatarUrl, onSend, disabled, loading, ...others }, ref) => {
    const [value, setValue] = React.useState('');

    const handleSend = () => {
      onSend?.(value);
      setValue('');
    };

    return (
      <div className={cn('flex w-full flex-row gap-4 border-1 border-white-300 p-4', className)}>
        <Avatar className='h-9 w-9 shrink-0 border-2 border-gray-100' src={avatarUrl} />

        <div className='flex w-full flex-col items-start gap-4'>
          <Textarea
            ref={ref}
            className={cn('w-full')}
            classNames={{
              inputWrapper: cn(
                'shadow-none bg-neutral-white p-0 w-full',
                'data-[hover=true]:!bg-neutral-white group-data-[hover=true]:!bg-neutral-white',
                'data-[focus=true]:!bg-neutral-white group-data-[focus=true]:!bg-neutral-white',
              ),
              innerWrapper: 'gap-2',
              input: cn(body(), 'text-neutral-black self-center'),
            }}
            disabled={disabled || loading}
            minRows={1}
            placeholder='What are your thoughts?'
            radius='none'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) handleSend();
            }}
            {...others}
          />

          <Button
            className='self-end'
            color='primary'
            disabled={disabled || loading}
            isLoading={loading}
            radius='none'
            variant='solid'
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </div>
    );
  },
);

CommentInput.displayName = 'CommentInput';

export default CommentInput;
