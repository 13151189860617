'use client';

import { Modal, ModalBody, ModalContent, ModalHeader, ModalProps } from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import React from 'react';

import { FollowingUserItem } from './_components/FollowingUserItem';

import { heading } from '@/theme/typography';
import { useUserFollowing } from '@/hooks/queries';

interface UserFollowModalProps extends Omit<ModalProps, 'children'> {
  username: string;
}

const UserFollowingModal: React.FC<UserFollowModalProps> = ({ username, ...others }) => {
  const { followings } = useUserFollowing(username);

  return (
    <Modal
      {...others}
      classNames={{
        base: 'min-h-[50vh] max-w-lg',
        closeButton: 'top-4 right-4 text-[24px] rounded-none',
        ...others.classNames,
      }}
      radius='none'
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader
              className={cn(
                heading({ size: 'h6', weight: 'medium' }),
                'self-center p-6 text-center',
              )}
            >
              Following
            </ModalHeader>

            <ModalBody className='pb-6 pt-0'>
              <div className='flex flex-col gap-6'>
                {followings?.map((following) => (
                  <FollowingUserItem
                    key={following.id}
                    avatar={following.avatar || undefined}
                    id={following.id}
                    name={following.name}
                    username={following.username}
                  />
                ))}
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UserFollowingModal;
