'use client';

import { Button, ButtonProps } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import { HandsClapping } from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';

import { humanizeNumber } from '@/utils/number';
import useUpdateLikeMutation from '@/hooks/mutations/useUpdateLikeMutation';

interface ReactButtonProps extends ButtonProps {
  count: number;
  onClick?: () => void;
  currentId: number;
  currentType: 'article' | 'photo' | 'album';
  isLiked?: boolean;
}

const ReactButton: React.FC<ReactButtonProps> = ({
  count,
  onClick,
  className,
  currentId,
  currentType,
  isLiked,
  ...others
}) => {
  const [reacted, setReacted] = React.useState(isLiked);
  const [localCount, setLocalCount] = React.useState(count);

  React.useEffect(() => {
    setLocalCount(count);
    setReacted(isLiked);
  }, [count, isLiked]);

  const { likeMutation, isLiking } = useUpdateLikeMutation();

  const handleReact = () => {
    setReacted((prev) => {
      if (!prev) {
        setLocalCount(localCount + 1);
      } else {
        setLocalCount(localCount - 1);
      }

      return !prev;
    });
    likeMutation.mutate({ id: currentId, type: currentType });
  };

  return (
    <Button
      className={cn(
        'flex w-auto min-w-0 flex-row items-center gap-2 px-2',
        'data-[hover=true]:text-primary',
        className,
        { 'text-primary': reacted },
      )}
      startContent={<HandsClapping size={24} weight={reacted ? 'fill' : 'regular'} />}
      variant='light'
      onClick={handleReact}
      {...others}
    >
      {humanizeNumber(localCount)}
    </Button>
  );
};

export default ReactButton;
