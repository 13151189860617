'use client';

import { Avatar } from '@nextui-org/avatar';
import { Button } from '@nextui-org/button';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalProps } from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import React from 'react';
import Link from 'next/link';

import { body, caption, heading } from '@/theme/typography';
import { useUserFollowers } from '@/hooks/queries';

interface UserFollowModalProps extends Omit<ModalProps, 'children'> {
  username: string;
}

const UserFollowingModal: React.FC<UserFollowModalProps> = ({ username, ...others }) => {
  const { followers } = useUserFollowers(username);

  return (
    <Modal
      {...others}
      classNames={{
        base: 'min-h-[50vh] max-w-lg',
        closeButton: 'top-4 right-4 text-[24px] rounded-none',
        ...others.classNames,
      }}
      radius='none'
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader
              className={cn(
                heading({ size: 'h6', weight: 'medium' }),
                'self-center p-6 text-center',
              )}
            >
              Followers
            </ModalHeader>

            <ModalBody className='pb-6 pt-0'>
              <div className='flex flex-col gap-6'>
                {followers?.map(({ id, name, username, avatar }) => (
                  <div key={id} className='flex flex-row items-center justify-between gap-6'>
                    <Link className='flex flex-row gap-5' href={`/${username}`}>
                      <Avatar className='h-12 w-12 border-2 border-gray-100' src={avatar?.url} />

                      <div className='flex flex-col'>
                        <div className={cn(body({ weight: 'medium' }))}>{name}</div>
                        <div className={cn(caption(), 'text-gray-500')}>@{username}</div>
                      </div>
                    </Link>

                    <Button className='border-gray-50' radius='none' variant='bordered'>
                      Following
                    </Button>
                  </div>
                ))}
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UserFollowingModal;
